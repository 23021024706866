import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav` 
height: 48px;
display: flex;
justify-content: space-between;
padding: 0.2rem calc((100vw - 1280px) / 2);
z-index: 2; 
@media screen and (max-width: 768px) {
	height: 42px;
}
`;

export const NavLink = styled(Link)`
color: #AEAEAE;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem; 
cursor: pointer;
&.active {
	color: #FFFFFF;
}
`;

export const Bars = styled(FaBars)`
display: none;
color: #c0c0c0;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	z-index: 2;  
	top: -14px;
	right: 0;
	margin-right: -14px;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
	&:hover {
		transition: all 0.1s ease-in-out; 
		color: #fff;
	}
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-left: 4px;  
`;

export const NavBtn = styled.nav`
display: flex;
align-items: right;
float: 'right'; 
@media screen and (max-width: 768px) {
	display: none;
}
`;
