import React from 'react';
import {Helmet} from "react-helmet";
import '../index.css';
import { NavLink } from 'react-router-dom';

const privacyPolicy = () => {
return (
	<div  className="blurBg"
	style={{ 	display: 'flex',
    flexDirection: 'column',
		justifyContent: 'Left',
		alignItems: 'Left', 
    color : '#ffffff',
	}
}
	> 
  
  <Helmet>
        <meta charSet="utf-8" />
        <title>Youtubers Clan- Privacy Policy</title>     
				<meta name="description" content="Youtubers Clan App has certain privacy policies regarding the collection, use, and disclosure of Personal Information. This App will help YouTubers to gain Subscribers, Views, and likes for their videos through promotions." />
				<meta name="keywords" content="Youtubers, YoutubersClan, Privacy Policy, Youtubers Clan, Home, Youtuber Clan App, YT Clan, Boost Subscribers, Increase Video Views, Promote Channel" />
				<meta name="author" content="Megamind Core"/>
  </Helmet>

  <div className="privacyPolicyText">
    <br/>  
    <br/>
  </div>
  <div className="privacyPolicyHeading">Privacy Policy</div>
  <div className="privacyPolicyText">
    The YoutubersClan App is a Free app that is provided at no cost. But it uses In-App Purchases to make it profitable.
  </div>
  <div className="privacyPolicyText">
    This page is used to inform visitors regarding my
    policies with the collection, use, and disclosure of Personal
    Information if anyone decided to use my Service.
    <br/>
    <br/>
  </div> 
  <div className="privacyPolicyText">
    If you choose to use my Service, then you agree to
    the collection and use of information in relation to this
    policy. The Personal Information that I collect is
    used for providing and improving the Service. I will not use or share your information with
    anyone except as described in this Privacy Policy.
  </div> 
  <div className="privacyPolicyText">
    The terms used in this Privacy Policy have the same meanings
    as in our Terms and Conditions, which are accessible at
    YoutubersClan App unless otherwise defined in this Privacy Policy.
  </div> 
  <div className="privacyPolicySubHeading">
    Information Collection and Use
  </div> 
  <div className="privacyPolicyText">
  For a better experience, while using our Service, I
  may require you to provide us with certain personally
  identifiable information, including but not limited to 
  <ul>
    <li>Nick Name</li>
    <li>Profile Photo</li>
    <li>Age</li>
    <li>Gender</li>
    <li>Country</li>
  </ul>
  The information that I request will be retained on your device
  and highly secured server. It is not collected by us or any third party
  in any way.  
  </div>
  <div className="privacyPolicyText">
    The app does use third-party services that may collect
    information used to identify you. 
    Link to the privacy policy of third-party service providers used
    by the app
   <ul>
      <li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
      <li><a href="https://marketingplatform.google.com/about/analytics/terms/us" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
      <li> YouTube API Services 
        <a href="https://developers.google.com/youtube/terms/api-services-terms-of-service" target="_blank" rel="noopener noreferrer"> Terms </a> and 
        <a href="https://developers.google.com/youtube/terms/developer-policies" target="_blank" rel="noopener noreferrer"> Policies</a></li>
    </ul>
  </div>
  <div className="privacyPolicyText">
    User may request their information and account deletion through the following link- <NavLink to='/userAccountDeletion' >User Account Deletion</NavLink>
  </div>
  <div className="privacyPolicySubHeading">
      Log Data
  </div>  
  <div className="privacyPolicyText">
    I want to inform you that whenever you
    use my Service, in a case of an error in the app
    I collect data and information (through third-party
    products) on your phone called Log Data. This Log Data may
    include information such as your device Internet Protocol
    (“IP”) address, device name, operating system version, the
    configuration of the app when utilizing my Service,
    the time and date of your use of the Service, and other
    statistics.
  </div>
  <div className="privacyPolicySubHeading">
    Cookies
  </div>  
  <div className="privacyPolicyText">
  Cookies are files with a small amount of data that are
  commonly used as anonymous unique identifiers. These are sent
  to your browser from the websites that you visit and are
  stored on your device's internal memory.
  </div> 
  <div className="privacyPolicyText">
  This Service does not use these “cookies” explicitly. However,
  the app may use third-party code and libraries that use
  “cookies” to collect information and improve their services.
  You have the option to either accept or refuse these cookies
  and know when a cookie is being sent to your device. If you
  choose to refuse our cookies, you may not be able to use some
  portions of this Service. 
  </div>   
  <div className="privacyPolicySubHeading">
    Service Providers
  </div>   
  <div className="privacyPolicyText">
    I may employ third-party companies and
    individuals due to the following reasons: 
  <ul>
    <li>To facilitate our Service;</li> 
    <li>To provide the Service on our behalf</li> 
    <li>To perform Service-related services</li> 
    <li>To assist us in analyzing how our Service is used.</li>
  </ul> 
  I want to inform users of this Service
  that these third parties have access to their Personal
  Information. The reason is to perform the tasks assigned to
  them on our behalf. However, they are obligated not to
  disclose or use the information for any other purpose.
  </div>   
  <div className="privacyPolicySubHeading">
    Security
  </div>   
  <div className="privacyPolicyText">  
  I value your trust in providing us your
  Personal Information, thus we are striving to use commercially
  acceptable means of protecting it. But remember that no method
  of transmission over the internet, or method of electronic
  storage is 100% secure and reliable, and I cannot
  guarantee its absolute security.
  </div>   
  <div className="privacyPolicySubHeading">
    Links to Other Sites
  </div>   
  <div className="privacyPolicyText">
    This Service may contain links to other sites. If you click on
    a third-party link, you will be directed to that site. Note
    that these external sites are not operated by us.
    Therefore, I strongly advise you to review the
    Privacy Policy of these websites. I have
    no control over and assume no responsibility for the content,
    privacy policies, or practices of any third-party sites or
    services.
  </div>   
  <div className="privacyPolicySubHeading">
    Children’s Privacy
  </div>   
  <div className="privacyPolicyText"> 
    These Services do not address anyone under the age of 18.
    I do not knowingly collect personally
    identifiable information from children under 18 years of age. In the case
    I discover that a child under 18 has provided
    us with personal information, I immediately
    delete this from our servers. If you are a parent or guardian
    and you are aware that your child has provided us with
    personal information, please contact us so that
    I will be able to do the necessary actions.
  </div>   
  <div className="privacyPolicySubHeading"> 
    Changes to This Privacy Policy
  </div>   
  <div className="privacyPolicyText"> 
    I may update our Privacy Policy from
    time to time. Thus, you are advised to review this page
    periodically for any changes. I will
    notify you of any changes by posting the new Privacy Policy on
    this page.
  </div>   
  <div className="privacyPolicyText"> 
    This policy is effective as of 1 February 2024
  </div>   
  <div className="privacyPolicySubHeading">
    Contact Us
  </div>   
  <div className="privacyPolicyText"> 
    If you have any questions or suggestions about my
    Privacy Policy, do not hesitate to contact us at <b>
      <NavLink
            to='#'
            onClick={(e) => {
                window.location.href = "mailto:support@youtubersclan.com";
                e.preventDefault();
            }}
        >
        support@youtubersclan.com
      </NavLink></b>
  </div>   
  
  <div className="privacyPolicyText">
    <br/>  
    <br/>
  </div>
	</div>
);
};

export default privacyPolicy;
