import React , {useState} from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'; 
import { useNavigate } from 'react-router-dom';
import { toast} from "react-toastify";
import { selectUser } from '../components/auth/userAuth';
import DeleteIcon from '@material-ui/icons/Delete'; 
import { Button, DialogTitle, DialogActions, Dialog} from "@material-ui/core";
import axios from 'axios';
import { DNA } from 'react-loader-spinner';
import '../index.css';

const UserAccountDeletion = () => {
  const [alert, setAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const domain= "https://youtubersclan.com"  
  const port="40000"; 


  const alertClose = () => {
    setAlert(false);
  };

  const currentUser = useSelector(selectUser);
	const navigate = useNavigate();
  const displayLoginNotification = (e) => {
    e.preventDefault(); 
    if(!currentUser ){
      toast.warning("Please Log In!");
      navigate('/signIn');
    }else{ 
      setAlert(true);
    }
  };


  const userAccountDeletion = async (e) => {
    e.preventDefault(); 
    alertClose();
    const route="api/youtubersClan/gTransaction";
    var emailId = currentUser.emailId; 
    var uid = currentUser.uid;
    try {
      setIsLoading(true);
        await  axios
      .post(domain+":"+port+"/"+route, {
        dataRequest:"setTransactionDetails",
        productType:"userAccountDeletion",
        orderId:"........",
        purchaseState:-1,
        emailId:emailId,
        uid:uid,
        purchaseToken:"........",
        purchaseTime:String(Date.now()),
        quantity:1,
        amount:"₹0.00INR",
      })
      .then((response) => {
        setIsLoading(false);
        if(response.status===200){
          if(response.data.head.resultCode===1){
            toast.success("Your account will be deleted within 10 minutes!");   
            navigate('/home');
          }else{
            toast.warning("No Response Received!"); 
          }
        }else{
          toast.error(response.statusText); 
        }
      })
      .catch(function (err) {
        setIsLoading(false);
        toast.warning(err.message); 
      });
    } catch (err) {
      setIsLoading(false);
      toast.error(err); 
    } finally {
      setIsLoading(false);
    }
  };

return (
	<div  className="blurBg"
	style={{ 	 
    display: 'flex',
    flexDirection: 'column',
		justifyContent: 'Left',
		alignItems: 'Left',
		height: '100vh',
    color : '#ffffff', 
    
	}
}
	> 
  
  
  <Helmet>
        <meta charSet="utf-8" />
        <title>Youtubers Clan- Account Deletion</title>     
				<meta name="description" content="Users may request their account deletion and erase all the information stored by the Youtubers Clan App. This App will help YouTubers to boost Subscribers, Views, and likes through promotions." />
				<meta name="keywords" content="Users Account Deletion, Youtubers, YoutubersClan, Privacy Policy, Youtubers Clan, Youtuber Clan App, YT Clan" />
				<meta name="author" content="Megamind Core"/>
  </Helmet>
	
  <DNA 
    visible={isLoading}
    height="136"
    width="136"
    ariaLabel="dna-loading"
    wrapperStyle={{ 
      "z-index": "2", 
      "textAlign":"center",
      "position":"absolute",
      "top":"50%",
      "left":"50%",
      "marginRight":"-50%",
      "transform":"translate(-50%, -50%)"
    }}
    wrapperClass="dna-wrapper"
  />


  <div className="privacyPolicyText">
    <br/>  
    <br/>
  </div>
  <div className="privacyPolicyHeading">User Account Deletion</div>
  <div className="privacyPolicySubHeading">
  Information Collection
  </div>  
  <div className="privacyPolicyText">
    Users can delete all account information collected by Application including :
    <ul>
    <li>Nick Name</li>
    <li>Profile Photo</li>
    <li>Age</li>
    <li>Gender</li>
    <li>Country</li>
    </ul>
  </div>
  <div className="privacyPolicySubHeading">
  Internal Information
  </div>   
  <div className="privacyPolicyText">
    Moreover, Application Internal Information will also be deleted but not limited to:
    <ul>
    <li>Diamonds or Points</li>
    <li>Memberships</li>
    <li>Disable Account</li> 
    <li>Other Data</li>
    </ul>
  </div> 
  <div className="privacyPolicyText">
    Login using App User ID and Password is required for Account Deletion within 10 minutes. 
  </div> 
  <div className="signInLogIn">
    <Button  startIcon={<DeleteIcon />} color="secondary" fullWidth variant="contained"  onClick={(e)=>displayLoginNotification(e)} >
      Delete Account
    </Button>
  </div>  
      <Dialog
        open={alert}
        onClose={alertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you really want to delete your account?"}
        </DialogTitle> 
        <DialogActions>
          <Button  color="secondary"  onClick={alertClose}>No</Button>
          <Button  color="primary"  onClick={(e)=>userAccountDeletion(e)} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog> 
  <div className='delete-button' onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.onCancel() } } />  
  <div className="privacyPolicyText">
    <br/>  
    <br/>
  </div>
	</div>
); 
};

export default UserAccountDeletion;
