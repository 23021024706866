import React , { useState } from 'react';
import styled from "styled-components";
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn, 
} from './topNavbarStyle';
import '../../index.css';
import { selectUser } from '../auth/userAuth';
import { useSelector,useDispatch } from 'react-redux'; 
import { logout } from '../auth/userAuth'; 
import { toast} from "react-toastify";
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import LazyImage from "react-lazy-progressive-image";
const logo = require('../../assets/ic_launcher_round.png')
const logoPlaceholder = require('../../assets/ic_launcher_round_micro.png')
const userImg = require('../../assets/ui_profile_logo_96x96.png')
const userImgPlaceholder = require('../../assets/ui_profile_logo_96x96_micro.png')

 
  function ExpandedSideBar(props) {  
	return (
		<div class="sidenav" style={{ width: props.isActive? '200px':'0px'  }}>
		<NavLink to='/home'  onClick={() => { props.setIsActive(isActive => !isActive); }} >Home</NavLink> 
		<NavLink to='/privacyPolicy'  onClick={() => { props.setIsActive(isActive => !isActive); }} >Privacy Policy</NavLink> 
		{props.currentUser ? (<NavLink to='/signIn' onClick={(e)=>{logoutHandle(props.dispatch,e); props.setIsActive(isActive => !isActive);}} >LogOut</NavLink>) :(<NavLink to='/signIn'  onClick={() => { props.setIsActive(isActive => !isActive); }} >LogIn</NavLink>)}   
		</div> 
	);
  }

const logoutHandle= (dispatch,e) =>{
	e.preventDefault(); 
	dispatch(logout());
	toast.success("LogOut Successfully!");
}
const Navbar = () => {
	const currentUser = useSelector(selectUser);
	const [isActive, setIsActive] = useState(false);
	const [imageUser, setImage] = useState("");  
	const dispatch= useDispatch();
	const navigate = useNavigate(); 

	const userName= () =>{
		var userName="Unknown";
		if (currentUser && (currentUser.name!=null)){
			userName= currentUser.name.substring(1); 
			if(userName.length>0){userName=userName.substring(0,1).toUpperCase()+userName.substring(1);} 
		}
		return userName;  
	} 

	const getUserImage =  () => { 
		const domain= "https://youtubersclan.com"  
		const port="40000"; 
		const route="api/youtubersClan/file.data"; 
		if (currentUser && (currentUser.name!=null)){
			axios.get(domain+":"+port+"/"+route, { 
				headers: { 
					datarequest: 'getFile',
					datatype: 'userProfile',
					datacontenttype: 'Images/64x',
					id:currentUser.emailId,
					uid:currentUser.uid,
					userid:currentUser.emailId
				},
				responseType: 'arraybuffer'
			})
			.then((res) => { 
				let data = new Uint8Array(res.data);
				let raw = String.fromCharCode.apply(null, data);
				let base64 = btoa(raw);
				let src = "data:image;base64," + base64;
		
				setImage(src); 
			});
		}else{
			setImage(""); 
		}
		return userImgPlaceholder;
	}
	const Image = styled.img`
					transition: all 0.25s ease;
					opacity: ${props => (props.loading ? 0.2 : 1)};
					`; 

return (
	<>  
	<ExpandedSideBar isActive={isActive} setIsActive={setIsActive} currentUser={currentUser} dispatch={dispatch}/> 
	<div className='header'>
		<Nav>
			<Bars onClick={() => { setIsActive(isActive => !isActive); }} /> 				  
			<NavMenu>  
				<div className='navHeaderLogo'>  
					<NavLink to='/' >
						<div className='navHeaderLogoImgBox'> 
							<div className='navHeaderLogoImg'> 
								<LazyImage
									placeholder={logoPlaceholder} 
									src={logo} 
								> 
									{(src, loading, isVisible) => 
									<Image   
										src={src}  
										title={"Home"} 
										alt={"Youtuber Clan Logo"}  
										id='navHeaderLogoStyle' 
									/>}
								</LazyImage>
							</div>
						</div>
						<div className='navHeaderLogoTextBox'><div className='navHeaderLogoText'>Youtubers Clan</div> </div>
					</NavLink>
				</div>
			</NavMenu> 
			<div className='navHeaders'> 
				<NavBtn>
					<NavLink to='/home' >Home</NavLink> 
					<NavLink to='/privacyPolicy' >Privacy Policy</NavLink> 
					{currentUser ? (<NavLink to='/signIn' onClick={(e)=>{logoutHandle(dispatch,e);}} >LogOut</NavLink>) :(<NavLink to='/signIn'>LogIn</NavLink>)}   
				</NavBtn> 
				<NavLink >   
					<LazyImage
						placeholder={currentUser ?  getUserImage() : userImgPlaceholder } 
						src={currentUser ? imageUser :userImg } 
					>
        				{(src, loading, isVisible) => 
						<Image 
							onClick={(e)=> 
							{
							e.preventDefault(); 
							currentUser ? 
							(toast.success( "Welcome " + (userName()) +"!" )) :navigate('/signIn')	
							}
							}
							src={src} 
							loading={loading}
							effect="blur"  
							title={userName()} 
							alt={userName()}  
							id='signInImg' 
						/>}
					</LazyImage>
				</NavLink>
			</div>
		</Nav>
	</div>
	</>
);
};

export default Navbar;
