import styled from "styled-components";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React from "react";    	
import { toast} from "react-toastify";
import layer3 from '../assets/F3PromoteChannel__.png'; 
import layer33 from '../assets/F3PromoteChannel__micro.png'; 
import layer4 from '../assets/F4PromoteVideo__.png'; 
import layer44 from '../assets/F4PromoteVideo__micro.png'; 
import layer5 from '../assets/F5ViewProfile__.png'; 
import layer55 from '../assets/F5ViewProfile__micro.png'; 
import layer6 from '../assets/F6LiveChat__.png'; 
import layer66 from '../assets/F6LiveChat__micro.png'; 
import layer7 from '../assets/F7VideoStreaming__.png'; 
import layer77 from '../assets/F7VideoStreaming__micro.png'; 
import layer8 from '../assets/F8PremiumPromotion__.png'; 
import layer88 from '../assets/F8PremiumPromotion__micro.png'; 
import layer9 from '../assets/F9SearchChannel__.png'; 
import layer99 from '../assets/F9SearchChannel__micro.png'; 
import layer10 from '../assets/F10BuildClan__.png'; 
import layer100 from '../assets/F10BuildClan__micro.png'; 
import Card from "@material-ui/core/Card";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-toastify/dist/ReactToastify.css'; 
import LazyImage from "react-lazy-progressive-image";
import {Helmet} from "react-helmet";
import { Carousel } from 'react-responsive-carousel';	
import { useScrollPercentage } from "react-scroll-percentage";
import $ from 'jquery';

import '../index.css'; 
const playLogo = require('../assets/playIcon.png')
const playLogoPlaceholder = require('../assets/playIcon_micro.png')


const routeTo = () => { 
	window.open('https://play.google.com/store/apps/details?id=ml.megamindcore.youtuberssub4sub20','_self'); 
}


const AutoLaunch=() => {
	var  isRedirect = true; 
	const { pathname } = useLocation();
	useEffect(() => {
	if((pathname.replaceAll('/','')==="androidStore")){
			const CloseButton = ({ closeToast }) => (
					<b
					  className="material-icons"
					  onClick={() => { 
						isRedirect=false;
						closeToast();
					  }}
					>
					❌ &nbsp;
					</b>
				  );
			toast("Launching Youtubers Clan App", {
				icon: "🚀",
				autoClose: 5500,
				delay: 500,
				closeOnClick: true,
				closeButton: <CloseButton/>, 
				pauseOnHover: false,
				toastId: 101,
				onClose: () => {if(isRedirect){routeTo()}}, 
				}); 
		} 
	}, []);
}
  
function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
  }
  function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
	useEffect(() => {
	  function handleResize() {
		setWindowDimensions(getWindowDimensions());
	  }
  
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return windowDimensions;
  }
  
const HomeView = () => {
	const { width } = useWindowDimensions();
	const [ref, percentage] = useScrollPercentage({
		threshold: 0,
	  })
	  if(percentage.toPrecision(2)>=0.01){
		//toast.warning("percentage:"+percentage.toPrecision(2)); 
	  }
	  $(window).scroll(function(){
		$("#theFixed").css("top", Math.max(675 - $(this).scrollTop(),Math.min( (1375) -$(this).scrollTop(),0)));
	});

	const Image = styled.img`
					transition: all 0.25s ease;
					opacity: ${props => (props.loading ? 0.2 : 1)};
					`;
	return (
	<div >   
		
		<Helmet>
                <meta charSet="utf-8" />
                <title>Youtubers Clan- Home</title>        
				<meta name="description" content="Youtubers Clan App help the youtubers to boost up their channel through advertisements, chat with youtubers, and build a stronger profile. This App will help YouTubers to boost Subscribers, Views, and likes through promotions." />
				<meta name="keywords" content="Youtubers, YoutubersClan, Youtubers Clan, Home, Youtuber Clan App, YT Clan, Boost Channel, Boost Subscribers, Increase Video Views, Promote Channel " />
				<meta name="author" content="Megamind Core"/>
        </Helmet>
		<AutoLaunch/>
		<div
          style={{ 
            position: 'center',
			marginTop:'200px',
			
          }}
        >
			<div class="heading">The Youtubers Clan App</div>
			<div ><br></br></div>
			<div class="subHeading">Can't grow? Try free advertisements,</div>
			<div class="subHeading">Discover new YouTubers & Promote your Channel</div>
			<div class="subHeading">to thousands of Users.</div>
			<div class="downloadCard">
				<Card
					style={{ 
						width: 185,
						margin: "30px",
						borderRadius: "14px",
						overflow: "hidden",  
					}}
					onClick={() => {
						routeTo();
					  }}
					>
					<div class="downloadCardLogo">
						<div class="downloadCardLogoImg">
							<LazyImage
								placeholder={playLogoPlaceholder} 
								src={playLogo} 
								visibilitySensorProps={{partialVisibility:true}}
							> 
								{(src, loading, isVisible) => 
								<Image   
									src={src}  
									style={{width: '36px',height:'36px'}}
								/>}
							</LazyImage>  
						</div>
						<div class="downloadCardText">
							<div class="downloadCardTextHead">
								Download On
							</div>
							<div class="downloadCardTextSubHead">
								Play Store
							</div>
						</div>
					</div>
					</Card>
			</div>
		</div>  
		
		<div id="theFixed" style={{ 
		position: 'fixed',
		top:675,
	  }}
	    className='slideClass'>
		<Carousel className='slideClass'
			dynamicHeight={true}
			emulateTouch={true}
			swipeable={false}
			showIndicators={false}
			showThumbs={false}
			useKeyboardArrows={false}
			showStatus={false} 
			showArrows={false} 
			centerMode={((width>=540)?true:false)}
			centerSlidePercentage={((width>=758)?24:48)}
			selectedItem={Math.floor(percentage.toPrecision(2)*10)}
			>  
                <div >
					<LazyImage
						placeholder={layer33} 
						src={layer3} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="Promote Channel"
						/>}
					</LazyImage> 
                </div>
                <div>
                    
					<LazyImage
						placeholder={layer44} 
						src={layer4} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="Promote Video"
						/>}
					</LazyImage> 
                </div>
                <div>
                    
					<LazyImage
						placeholder={layer55} 
						src={layer5} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="View Profile"
						/>}
					</LazyImage> 
                </div>
                <div>
                    
					<LazyImage
						placeholder={layer66} 
						src={layer6} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src} 
							alt="Live Chat" 
						/>}
					</LazyImage> 
                </div>
                <div>
                    
					<LazyImage
						placeholder={layer77} 
						src={layer7} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="Video Streaming"
						/>}
					</LazyImage> 
                </div>
                <div>
                    
					<LazyImage
						placeholder={layer88} 
						src={layer8} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="Premium Promotion"
						/>}
					</LazyImage> 
                </div>
                <div>
                    
					<LazyImage
						placeholder={layer99} 
						src={layer9} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="Search Channel"
						/>}
					</LazyImage> 
                </div>
				<div>
					<LazyImage
						placeholder={layer100} 
						src={layer10} 
						visibilitySensorProps={{partialVisibility:true,minTopValue:10}}
					> 
						{(src, loading, isVisible) => 
						<Image   
							src={src}  
							alt="Build Clan"
						/>}
					</LazyImage> 
				</div>  
            </Carousel> 
		</div>  

  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/>  
  <br ref={ref} />
  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
  <br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
  <br/>  <br/>
</div>
)
}

const Home = () => {
return (
	HomeView()
);
};

export default Home;
