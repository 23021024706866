import { createSlice } from '@reduxjs/toolkit'; 

export const userAuth =  createSlice({
	name:"user",
	initialState:{
		user:null,
	},
	reducers:{
		login: (state,action) => {
			state.user= action.payload; 
		},
		logout: (state) => {
			state.user= null; 
		}
	},
});

export const {login,logout} = userAuth.actions;

export const selectUser= (state) => state.user;

export default userAuth.reducer;
