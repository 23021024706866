import React,{useEffect} from 'react';
import './App.css';
import Navbar from './components/navbar/topNavbar';
import Footer from './components/footer/bottomFooter.js';
import { BrowserRouter as Router, Routes, Route,useLocation } from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import Home from './pages/home.js';
import Policy from './pages/privacyPolicy.js'; 
import UserAccountDeletion from './pages/userAccountDeletion.js'; 
import SignIn from './pages/signIn.js'; 
import F0F from './pages/404.js'; 

const ScrollToTop = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [pathname]);
}

  
function App() {
return (  
	<Router>
		<ScrollToTop/>
	<Navbar/>
	<ToastContainer  position="bottom-right" />
	<Routes >
		<Route path='/' exact element={<Home/> } />
		<Route path='/home' exact element={<Home/>} />
		<Route path='/androidStore' exact element={<Home/>} />
		<Route path='/privacyPolicy' element={<Policy/>} />  
		<Route path='/userAccountDeletion' element={<UserAccountDeletion/>} />  
		<Route path='/signIn' element={<SignIn/>} />  
		<Route path='*' element={<F0F/>} />  
	</Routes>
    <Footer />
	</Router> 
);
}

export default App;
