import React from 'react'; 
import '../../index.css';
import { Button } from "@material-ui/core";
import { NavLink ,useNavigate} from 'react-router-dom'; 
 
 
const Footer = () => { 
const navigate = useNavigate();
return (
	<>  
	<div className='footer'>   
      <div class="footer-row">
        <div class="footer-col">
          <h4>Explore</h4>
          <ul class="links">
            <li><a href="https://www.youtube.com/c/YoutubersClan">Youtubers Clan Channel</a></li>
            <li><a href="http://play.google.com/store/apps/dev?id=5340805012522279260">Developer App Store</a></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Quick Links</h4>
          <ul class="links">
            <li><NavLink to='/userAccountDeletion' >User Account Deletion</NavLink></li>
            <li><NavLink to='/privacyPolicy' >Privacy Policy</NavLink></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>SignIn</h4>
          <p>
            Login into our website by using your App UserID and Password to access your account.
          </p> 
          <div style={{ color: '#ffffff', }}>
            <Button color="inherit" variant="outlinedPrimary"     onClick={(e)=>{ navigate('/signIn');}}>Log In</Button> 
          </div> 
       </div>
      </div>  
	  <hr
        style={{
            color: '#ffffff',
            backgroundColor: '#ffffff',
            height: '0.1px',
            marginLeft: '20px',
            marginRight: '20px',
        }}
		/>
		<div
		  style={{
			  color: '#ffffff',  
			  textAlign: 'center',
			   fontSize: '13px',
		  }}
		>
      Copyright © 2024 YoutubersClan.com | All rights reserved
		</div>
		<br/>
	</div>
	</>
);
};

export default Footer;
