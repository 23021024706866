import React, {useState} from 'react';
import {Helmet} from "react-helmet"; 
import { ThemeProvider,createMuiTheme, TextField,Button, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";          	
import { toast} from "react-toastify";
import axios from 'axios';
import { DNA } from 'react-loader-spinner';
import { useSelector,useDispatch } from 'react-redux'; 
import { selectUser } from '../components/auth/userAuth';
import 'react-toastify/dist/ReactToastify.css'; 
import '../index.css';
import { login } from '../components/auth/userAuth';
import { useNavigate } from 'react-router-dom';
import emailLogo from '../assets/ui_email_logo_48x48_white.png'; 
import Navbar from '../components/navbar/topNavbar';


const SignIn = () => {
  const dispatch= useDispatch();
	const navigate = useNavigate();
	const currentUser = useSelector(selectUser);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const domain= "https://youtubersclan.com"  
  const port="40000"; 
  const route="api/youtubersClan/userData"; 

  const displayLoginNotification = (e) => {
    e.preventDefault(); 
    if(userID===''){
      toast.warning("UserID empty!"); 
    }else if(password===''){
      toast.warning("Password empty!");
    }else if(isLoading){
      toast.warning("Already Processing Request!");
    }else if(currentUser){
      toast.warning("Already Log In!");
    }else{
      isAuthenticUser(userID,password,e);
    }
  };
  const isAuthenticUser = async (userID,password,e) => {
    e.preventDefault(); 
    try {
      setIsLoading(true);
      await  axios
      .post(domain+":"+port+"/"+route, {
        dataRequest: 'isAuthenticatedUser',
        emailId: userID,
        password: password,
      })
      .then((response) => {
        setIsLoading(false);
        if(response.status===200){
          if(response.data.head.resultCode===1){
            //toast.success("User Verified Successfully!"); 
            getUserDetails(userID,e);
          }else if(response.data.head.resultCode===-1){
            toast.warning("Wrong UserID!"); 
          }else if(response.data.head.resultCode===0){
            toast.warning("Wrong Password!"); 
          }else{
            toast.warning("Wrong UserName and Password!"); 
          }
        }else{
          toast.error(response.statusText); 
        }
      })
      .catch(function (err) {
        setIsLoading(false);
        toast.warning(err.message); 
      });
    } catch (err) {
      setIsLoading(false);
      toast.error(err); 
    } finally {
      setIsLoading(false);
    }
  };
   

  const getUserDetails = async (userID,e) => {
    e.preventDefault(); 
    try {
      setIsLoading(true);
      await  axios
      .post(domain+":"+port+"/"+route, {
        dataRequest: 'getUserSpecificDetails',
        emailId: userID,
        dataItem: ['emailId','age','gImage','gName','image','name','phone','uid','key'],
      })
      .then((response) => {
        setIsLoading(false);
        if(response.status===200){
          if(response.data.head.resultCode===1){
            if(response.data.body.uid){
              toast.success(response.data.body.name.substring(1)+" Login Successfully!");   
              dispatch(login({
                emailId:response.data.body.emailId,
                age:response.data.body.age,
                gImage:response.data.body.gImage,
                gName:response.data.body.gName,
                image:response.data.body.image,
                name:response.data.body.name,
                phone:response.data.body.phone,
                uid:response.data.body.uid,
                key:response.data.body.key, 
              }
              ));
              
              navigate('/home');
            }else{
              toast.warning("No User Data Found!"); 
            } 
          }else{
            toast.warning("No Response Received!"); 
          }
        }else{
          toast.error(response.statusText); 
        }
      })
      .catch(function (err) {
        setIsLoading(false);
        toast.warning(err.message); 
      });
    } catch (err) {
      setIsLoading(false);
      toast.error(err); 
    } finally {
      setIsLoading(false);
    }
  };

  const Theme = {
    palette: {
     primary: {
      contrastText: "#FFFFFF",
      dark: "#FFFFFF",
      main: "#FFFFFF",
      light: "#FFFFFF"
     }
   },
   overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#ffffffe7"
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#FFFFFF",
          "@media (hover: none)": {
            borderColor: "#FFFFFF"
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "#25d3ffd7",
          borderWidth: 1
        },
        '& .MuiInputBase-root': {
          color: 'white',
        },
        '& .MuiOutlinedInput-input': {
          color: 'white',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white',
          }, 
          '&.Mui-focused fieldset': {
            borderColor: 'white',
          },
        }, 
      }
     }
    }
   };

const theme = createMuiTheme(Theme);
return (
	<div
	style={{ 	display: 'flex',
    flexDirection: 'column',
		justifyContent: 'Left',
		alignItems: 'Left',
		height: '100vh',
    color : '#ffffff',
    marginLeft: '5vw' ,
    
	}
}
	> 
  
  
  <Helmet>
        <meta charSet="utf-8" />
        <title>Youtubers Clan- SignIn</title>     
				<meta name="description" content="Users may login on this website using their App UserId and Password. This App will help YouTubers to get more channel Subscribers, Views, and likes through advertisements." />
				<meta name="keywords" content="SignIn, LogIn, SignUp, Account Login, Youtubers, YoutubersClan, Youtubers Clan, Youtuber Clan App, YT Clan, Boost Subscribers, Increase Video Views, Promote Channel" />
				<meta name="author" content="Megamind Core"/>
  </Helmet>
	
  <DNA 
    visible={isLoading}
    height="136"
    width="136"
    ariaLabel="dna-loading"
    wrapperStyle={{ 
      "z-index": "2", 
      "textAlign":"center",
      "position":"absolute",
      "top":"50%",
      "left":"50%",
      "marginRight":"-50%",
      "transform":"translate(-50%, -50%)"
    }}
    wrapperClass="dna-wrapper"
  />
  <div className="signInCard">
    <div className="signInHeading">SignIn</div>
    <form>
      <ThemeProvider theme={theme}>
        <div className="signInUserID">
          <TextField
            label='UserID'
            variant="outlined"
            InputLabelProps={{
              style: { color: '#ffffffc7' }, 
            }}
            color="warning"
            type={"email"}  
            onChange={(e)=>{setUserID(e.target.value.toLowerCase())}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton  aria-label="Email Icon" > 
                  <img src={emailLogo} alt='UserId' style={{ height: 20, marginLeft: '4px' }}  />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />
        </div>    
        <div className="signInUserPassword">
          <TextField
            label='App Password'
            variant="outlined"
            onKeyPress={(event) => {

            }}
            InputLabelProps={{
              style: { color: '#ffffffc7' }, 
            }}
            type={showPassword ? "text" : "password"} 
            onChange={(e)=>{setPassword(e.target.value)}}
            InputProps={{ 
              onKeyPress: (event) => {
                if (event.key === "Enter") {
                  displayLoginNotification(event);
                  event.target.blur();
                }
              },
              onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                  {showPassword ? <Visibility  color="primary"/> : <VisibilityOff  color="primary"/>}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </ThemeProvider>
      <div className="signInForgot">
        <Button  variant="text" size="small" Alignment="inherit" color="inherit"   onClick={(e)=>{toast.warning("Reset using Youtubers Clan App");}} >
          Forgot password?
        </Button> 
      </div>  
      <div className="signInLogIn">
        <Button fullWidth variant="contained"  onClick={(e)=>displayLoginNotification(e)} color="primary">
          Log In
        </Button>
      </div>  
    </form>
    </div>
	</div>
);
};

export default SignIn;
