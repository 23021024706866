import React, { useState } from "react"; 
import {Helmet} from "react-helmet";
import '../index.css';
 

const F0Fview = () => {
	const ref = useState()
	return ( 
		<div>
			<Helmet>
					<meta charSet="utf-8" />
					<title>Youtubers Clan- Error</title>        
					<meta name="description" content="Youtubers Clan App help the youtubers to boost up their channel through advertisements, chat with youtubers, and build a stronger profile. This App will help YouTubers to boost Subscribers, Views, and likes through promotions." />
					<meta name="keywords" content="Youtubers, YoutubersClan, Youtubers Clan, 404, Youtuber Clan App, YT Clan, Boost Channel, Boost Subscribers, Increase Video Views, Promote Channel " />
					<meta name="author" content="Megamind Core"/>
			</Helmet>
			<div
			offset={0.2}
			speed={0.2}
			factor={1}
			style={{ 
				position: 'center'
			}}
			>	
						
			<br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
			<div class="heading">404 Error</div> 
			<div class="subHeading">Page Not Found</div>  
			<br/>  <br/>  <br  />  <br/>  <br/>  <br/>  <br  />  <br/> <br/>  <br/>  <br  />  <br/> 
			</div>
		</div>
	)
}

const F0F = () => {
return (
	F0Fview()
);
};

export default F0F;
